<template>
  <v-card flat class="mt-5">
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Documento Original</th>
              <th class="text-left">Documento Cliente</th>
              <th class="text-left">Estado</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in documentos.filter((el) => el.original.etapa.id !== 6)"
              :key="item.original.id"
              v-show="(item.cliente.url !== '' && item.cliente.estado === 0) || item.cliente.estado === 1"
            >
              <td v-if="typeof item.original !== 'undefined'">
                {{ item.original.nombre }}
              </td>
              <td v-if="typeof item.cliente !== 'undefined'">
                {{ item.cliente.nombre || ' - ' }}
              </td>
              <td>
                <v-icon v-if="item.cliente.estado === 1" color="warning" icon="mdi-check" size="x-large"
                  >mdi-close</v-icon
                >
                <v-icon v-if="item.cliente.estado === 2" color="success" icon="mdi-check" size="x-large"
                  >mdi-check</v-icon
                >
              </td>
              <td>
                <div class="d-flex justify-space-between">
                  <v-btn :disabled="!item.cliente.id" v-on:click="verDoc(item.cliente.url)" color="success">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table v-if="documentos.some((el) => el.original.etapa.id === 6)">
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="5">
                <h1>Documentos - Crédito Aprobado</h1>
              </th>
            </tr>
            <tr>
              <th class="text-left">Nombre</th>
              <th class="text-left">Vto. en Días Hábiles</th>
              <th class="text-left">Archivo</th>
              <th class="text-left">Observación</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in documentos.filter((el) => el.original.etapa.id === 6)"
              :key="item.original.id"
              :class="{ 'disabled-row': !item.original.status }"
            >
              <td>{{ item.original.nombre }}</td>
              <td>{{ item.original.vencimiento }}</td>
              <td>
                <v-file-input
                  v-if="!item.cliente.id"
                  v-model="item.value"
                  class="mt-5"
                  label="Subir archivo"
                  required
                  outlined
                  dense
                  show-size
                ></v-file-input>
                <span v-if="item.cliente.id">{{ item.cliente.nombre }}</span>
              </td>

              <td>
                <div style="display: flex; justify-content: space-between">
                  <v-btn
                    :disabled="Number.isInteger(item.cliente.id) || item.estadoVencimiento"
                    v-on:click="subirDoc(item.value, item.original.id, item.original.nombre)"
                    color="primary"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!item.cliente.id || !item.original.status"
                    v-on:click="verDoc(item.cliente.url)"
                    color="success"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.cliente.estado !== 2 && item.cliente.estado !== 0"
                    :disabled="!item.cliente.id || !item.original.status"
                    v-on:click="eliminarDoc(item.cliente.id)"
                    color="error"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  props: {
    estadoCreditoId: Number,
    lccId: String,
    lineaCreditoId: Number,
  },
  data: () => ({
    token: localStorage.getItem('token'),
    documentos: [],
  }),
  methods: {
    verDoc(url) {
      window.open(url);
    },
    subirDoc(documentoAdjunto, documentoId, nombreDocumentoOriginal) {
      const codigoDoc = nombreDocumentoOriginal.replace(/\s/g, '');

      const extension = documentoAdjunto.name.split('.').pop().toLowerCase();

      if (['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension) && documentoAdjunto.size > 300 * 1024 * 1024) {
        // 300 MB in bytes for videos
        this.$swal.fire('Alerta', 'El documento excede el tamaño máximo de 300 MB.', 'error');
        throw new Error('El documento excede el tamaño máximo de 300 MB.');
      } else if (
        !['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension) &&
        documentoAdjunto.size > 10 * 1024 * 1024
      ) {
        // 10 MB in bytes for images and other documents
        this.$swal.fire('Alerta', 'El documento excede el tamaño máximo de 10 MB.', 'error');
        throw new Error('El documento excede el tamaño máximo de 10 MB.');
      }

      if (['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension)) {
        console.log('Subiendo documento...');
      } else {
        console.log('Subiendo documento...', codigoDoc);
      }

      const timeoutId = setTimeout(() => {
        this.mostrarMensajeTimeout = true;
      }, 10000); // Set a timeout of 10 seconds
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionClientes`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };

      const formData = new FormData();
      formData.append('codigoDoc', codigoDoc);
      formData.append('lineacreditoclienteId', this.lccId);
      formData.append('lineaCredito_id', this.lineaCreditoId);
      formData.append('documentoId', documentoId);
      formData.append('adjunto', documentoAdjunto);

      axios
        .post(url, formData, header)
        .then((response) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          if (response.data.status === 'success') {
            this.getDocumentos();
          }
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          console.log(error);
        });
    },

    eliminarDoc(documentoId) {
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionClientes`;
      axios.delete(url, {
        data: { documento_id: documentoId, legajo: this.lccId },
        headers: { token: this.token },
      });
      this.getDocumentos();
    },
    getDocumentos() {
      const url = `${API_URL}api/documentacionClientes/servicio/${this.lineaCreditoId}/${this.lccId}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.documentos = response.data.listaDocumentosCliente;
          } else {
            this.documentos = [];
            this.mostrarLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.getDocumentos();
    console.log(this.estadoCredito);
  },
};
</script>
